<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
// import {onBeforeMount} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router"

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  /**
   * const store = useStore();
    const country = store.state.token_country;
    const hasContracts = store.state.countries.find(d => d.id == country).contracts;

    return {
      hasContracts
    }
   */
  setup(){
    const store        = useStore();
    const router       = useRouter();
    const country      = store.state.token_country;
    const countryObj   = store.state.countries.find(d => d.id == country);
    const hasContracts = countryObj ? countryObj.contracts : true;
    const type         = store.state.token_type;

    const logout = () => {
      store.dispatch("logout").then(() => {
        router.push({name : "LoginAdmin"});
      }).catch(() => {
        router.push({name : "LoginAdmin"});
      });
    }

    /**
     * TEMPLATE ELEMENTS
     * --------------------------------------------------------------------------------
     **/
    return {
      hasContracts,
      country,
      logout,
      countryObj,
      type
    }
  }
}
</script>
<template>
<section>
<div class="container">
  <h1>Administrador de Observadatos</h1>
  <div class="row mb-4 pb-4 mt-3">
    <div class="col mb-4">
      <router-link :to="{ name: 'IndicesAdmin'}" class="od_subnav_btn">
        Actualizar indicadores
      </router-link>
    </div>
    <div class="col mb-4" v-if="hasContracts || type == 1" >
      <router-link :to="{ name: 'ContractsAdmin', country}" class="od_subnav_btn">
        Actualizar contratos
      </router-link>
    </div>
    <div class="col mb-4">
      <router-link :to="{ name: 'PublicationsAdmin'}" class="od_subnav_btn">
        Actualizar publicaciones
      </router-link>
    </div>
    <div class="col mb-4" v-if="type == 1">
      <router-link :to="{ name: 'UsersAdmin'}" class="od_subnav_btn">
        Usuarios
      </router-link>
    </div>
    <div class="col mb-4" v-else>
      <router-link :to="{ name: 'UserAdmin'}" class="od_subnav_btn">
        Usuario
      </router-link>
    </div>
    <div class="col mb-4 text-right">
      <a class="od_subnav_btn" v-on:click="logout" href="#"> Salir </a>
    </div>
  </div>
  <router-view :country="country" />
</div>
</section>
</template>